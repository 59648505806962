import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { CountrySelectorComponent } from './pages/country-selector/country-selector.component';
import { HomeComponent } from './pages/home/home.component';
import { HowComponent } from './pages/how/how.component';
import { OrderComponent } from './pages/order/order.component';



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: ':lang/home', component:HomeComponent },
  { path: 'home', component:HomeComponent },
  { path: 'country-selector', component:CountrySelectorComponent },
  { path: 'how-it-works', component:HowComponent },
  { path: 'order', component:OrderComponent },
  { path: 'contact', component:ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
