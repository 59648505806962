<div class="footer">
    <div class="container">
        <div class="column">
            <div class="aligner">
                <h4>clickreview.se</h4>
                <p>Hercegovačka 19</p>
                <p>Sweden</p>
                <p>+381 64 16 00 007</p>
                <p>info@clickreview.se</p>
            </div>
        </div>
        <!-- <div class="column"> -->
            <!-- <h4>Contact</h4> -->
        <!-- </div> -->
        <div class="column link">
            <div class="aligner">
                <h4>Link</h4>
                <p [routerLink]="['/home']">{{'Why us' | translate}}</p>
                <p [routerLink]="['/how-it-works']">{{'How it Works' | translate}}</p>
                <p [routerLink]="['/order']">{{'Order' | translate}}</p>
                <p [routerLink]="['/contact']">{{'Contact' | translate}}</p>
            </div>
        </div>
    </div>
</div>