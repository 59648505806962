<div class="hero">
    <!-- <div class="container"> -->
    <div class="bg-overlay"></div>
        <video [muted]="true" autoplay loop playsinline>
            <source src="assets/images/hero-new.mp4" type="video/mp4">
        </video>
        <div class="hero-content">
            <h1>{{'RAISE YOUR ONLINE RANKING TO THE NEXT LEVEL WITH CLICK REVIEW' | translate}}</h1>
            <p>{{'Click review’s unique solution allows you to improve your online reputation and visibility.' | translate}}</p>
            <button [routerLink]="['/contact']">{{'Order now' | translate}}</button>
        </div>
        <!-- <div class="hero-description">
            <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
            <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
                loyal social following – all in under a minute!</p>
            <button>GET MY CLICKREVIEW CARD</button>
        </div> -->
    <!-- </div> -->
</div>
<!-- <div class="mobile-description">
    <h2>Building customer reviews has <strong>NEVER</strong> been this easy!</h2>
    <p>With <strong><i>ClickReview</i></strong>, you can easily boost your online review count while building a
        loyal social following – all in under a minute!</p>
    <button>GET MY CLICKREVIEW CARD</button>
</div> -->
<div class="container card-container">
    <div class="hero-description">
        <h2 [innerHTML]="'Building customer reviews has NEVER been this easy!' | translate"></h2>
        <p [innerHTML]="'With ClickReview, you can easily boost your online review count while building a loyal social following – all in under a 30 seconds!' | translate"></p>
        <div class="btn-wrapper"><button [routerLink]="['/contact']">{{'GET MY CLICKREVIEW' | translate | uppercase}}</button></div>
    </div>
    <h4>{{'Did you know that…' | translate}}</h4>
    <div class="card-wrapper">
        <div class="card">
            <p>{{'86% of consumers prefer investing in services from higher-rated companies?' | translate}}</p>
        </div>
        <div class="card">
            <p>{{'Or, that 74% of customers turn to social media to inspire their buying decisions?'| translate}}</p>
        </div>
        <div class="card">
            <p>{{'Or, that 90% of people avoid businesses with an average rating score below 3.3?' | translate}}</p>
        </div>
    </div>
</div>
<div class="container">
    <div class="phone-wrapper">
        <div class="phone-part">
            <img src="assets/images/instagram.svg" class="icon instagram" alt="">
            <img src="assets/images/google.svg" class="icon google" alt="">
            <img src="assets/images/appstore.svg" class="icon appstore" alt="">
            <img src="assets/images/tik-tok.svg" class="icon tiktok" alt="">
            <div class="left-phone">
                <h4 [innerHTML]="'You Know Your Services Rule. But Do Your Customers?' | translate"></h4>
                <p>{{"With ClickReview, you'll be able to show the whole world how good you are. Let us help you skyrocket
                    your digital reputation in no time" | translate}}.</p>
            </div>
        </div>
        <div class="phone-part">
            <img class="phone" src="assets/images/cards.png" alt="">
        </div>
        <div class="phone-part">
            <img src="assets/images/facebook.svg" class="icon facebook" alt="">
            <img src="assets/images/website.svg" class="icon website" alt="">
            <img src="assets/images/tripadvisor.svg" class="icon trip" alt="">
            <img src="assets/images/google-play.svg" class="icon play" alt="">
            <img src="assets/images/folder.svg" class="icon folder" alt="">
            <div class="left-phone right">
                <h4 [innerHTML]="'ClickReview is the Answer…' | translate"></h4>
                <p>{{'Having a great business isn’t enough if no one knows about it. With ClickReview, you can confidently
                    give the world a reason to choose you again and again, because your online reputation will speak for
                    itself!' | translate}}</p>
            </div>
        </div>
    </div>
</div>
<div class="button-wrapper">
    <button [routerLink]="['/contact']">{{'GET MY CLICKREVIEW' | translate | uppercase}}</button>
</div>
<app-subscribe></app-subscribe>
<!-- <div class="container">
    <div class="references-wrapper">
        <img src="assets/images/st.regis.svg" alt="">
        <img src="assets/images/hilton.svg" alt="">
        <img src="assets/images/hyat.svg" alt="">
        <img src="assets/images/marrlott.svg" alt="">
        <img src="assets/images/Four_Seasons_logo Copy 3.svg" alt="">
    </div>
</div> -->