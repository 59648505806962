import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private scroll: ScrollService, public translate: TranslateService, private cookieService: CookieService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.scroll.setScroll()
    var snapshot = this.route.snapshot;
    const params = { ...snapshot.params };
    if(params.lang) {
      this.translate.use(params.lang)
      delete params.lang
      this.router.navigate(['/home'], { queryParams: params });
    }
  }

}
