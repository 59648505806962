import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-how',
  templateUrl: './how.component.html',
  styleUrls: ['./how.component.scss']
})
export class HowComponent implements OnInit {
  lang;

  constructor(private scroll: ScrollService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
    this.lang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.lang = res.lang
    });
  }


  playVid() {
    let vid = <HTMLVideoElement> document.getElementById("myVideo");
    vid.play();
  }

  pauseVid() {
    let vid = <HTMLVideoElement> document.getElementById("myVideo");
      vid.pause();
  }

}
