<div class="hero">
    <div class="container">
        <div class="hero-description">
            <h2 [innerHTML]="'Are you missing out on reviews from satisfied customers?' | translate"></h2>
            <p [innerHTML]="'With ClickReview, it’s easy for satisfied customers to have their say and inspire others – so that you can grow your social media following in under 30 seconds!' | translate"></p>
            <div class="social-icons">
                <div class="icons">
                    <img src="assets/images/instagram.svg" alt="">
                    <img src="assets/images/facebook.svg" alt="">
                    <img src="assets/images/google.svg" alt="">
                    <img src="assets/images/website.svg" alt="">
                    <img src="assets/images/tripadvisor.svg" alt="">
                    <img src="assets/images/tik-tok.svg" alt="">
                    <img src="assets/images/appstore.svg" alt="">
                    <img src="assets/images/google-play.svg" alt="">
                    <img src="assets/images/folder.svg" alt="">
                </div>
                <div class="text">{{'and a lot of more' | translate}}</div>
            </div>
        </div>
    </div>
</div>
<div class="mobile-description">
    <h2 [innerHTML]="'Are you missing out on reviews from satisfied customers?' | translate"></h2>
    <p [innerHTML]="'With ClickReview, it’s easy for satisfied customers to have their say and inspire others – so that you can grow your social media following in under 30 seconds!' | translate"></p>
    <div class="social-icons">
        <img src="assets/images/instagram.svg" alt="">
        <img src="assets/images/facebook.svg" alt="">
        <img src="assets/images/google.svg" alt="">
        <img src="assets/images/website.svg" alt="">
        <img src="assets/images/tripadvisor.svg" alt="">
    </div>
</div>
<div class="container">
    <div class="review-text">
        <h4>{{'Never Miss Another Review' | translate}}</h4>
        <p>{{'Get YOUR Clickreview Card Today!' | translate}}</p>
    </div>
    <div class="card-wrapper">
        <div class="card">
            <h5>{{'CONTACT FOR PRICING' | translate}}</h5>
            <p>{{'Start a conversation with our team to find a ClickReview package as unique as your business!' | translate}}</p>
            <div class="button-wrapper">
                <button [routerLink]="['/contact']">{{'Send request' | translate | uppercase}}</button>
            </div>
        </div>
        <div class="card">
            <h5>{{'Need to chat? Got a question?' | translate | uppercase}}</h5>
            <p>{{'Simply fill out the form to the right, and our team will get back to you as soon as possible – usually within one business day.' | translate}}</p>
            <div class="button-wrapper">
                <button [routerLink]="['/contact']">{{'Contact us' | translate | uppercase}}</button>
            </div>
        </div>
    </div>
</div>
<app-subscribe></app-subscribe>
<!-- <div class="container">
    <div class="references-wrapper">
        <img src="assets/images/st.regis.svg" alt="">
        <img src="assets/images/hilton.svg" alt="">
        <img src="assets/images/hyat.svg" alt="">
        <img src="assets/images/marrlott.svg" alt="">
        <img src="assets/images/Four_Seasons_logo Copy 3.svg" alt="">
    </div>
</div> -->