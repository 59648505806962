<div class="main-wrapper">
    <div class="container">
        <div class="intro-wrapper">
            <h2 [innerHTML]="'How Does ClickReview Work?' | translate"></h2>
            <div class="image-wrapper">
                <!-- <img src="assets/images/playy.svg" alt="" class="play-img" (click)="playVid()"> -->
                <!-- <video controls *ngIf="lang === 'de'">
                    <source src="assets/images/how.mp4" type="video/mp4">
                </video> -->
                <video controls>
                    <source src="assets/images/how-en.mp4" type="video/mp4">
                </video>
                <!-- <img src="assets/images/video.png" alt="" class="main-img">
                 -->
            </div>
        </div>
        <div class="crp-wrapper">
            <div class="crp">
                <div class="icon-wrapper">
                    <img src="assets/images/smartphone.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW BOARD' | translate}}</h4>
                <h6 [innerHTML]="'No Installation. No Hassle!' | translate"></h6>
                <p>{{'All your customers need to do is bring their smartphone near the CLICKREVIEW board, and they’ll instantly be taken to your landing page to leave a great review in moments!' | translate}}</p>
            </div>
            <div class="crp">
                <div class="icon-wrapper">
                    <img src="assets/images/smartphone.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW PAGE' | translate}}</h4>
                <h6 [innerHTML]="'Online Platforms Your Customers Love!' | translate"></h6>
                <p>{{'We empower your customers to have their say on the world’s most popular online review platforms, all at the touch of a button. They can even follow you on the socials in a click!' | translate}}</p>
            </div>
            <div class="crp">
                <div class="icon-wrapper laptop">
                    <img src="assets/images/laptop.svg" alt="">
                </div>
                <h4>{{'THE CLICKREVIEW ADMIN PANEL' | translate}}</h4>
                <h6 [innerHTML]="'Monitor Your Progress and Collect Feedbacks!' | translate"></h6>
                <p>{{'Easily set your landing page up with accompanying CLICKREVIEW boards, track stats, and unlock feedback on your service quality in real-time!' | translate}}</p>
            </div>
        </div>
        <div class="options-wrapper">
            <div class="left-side">
                <h3 [innerHTML]="'Why Choose ClickReview Pro?' | translate"></h3>
                <ul>
                    <li>{{'Boost your average rating on the world’s most used review platforms, from Google to Facebook, TripAdvisor, and beyond' | translate}}</li>
                    <li>{{'Analyze consumer interest in existing and new products to determine your upsell potential' | translate}}</li>
                    <li>{{'Easily grow your following on social media without stretching your budget' | translate}}</li>
                    <li>{{'Effortlessly soar up search results' | translate}}</li>
                    <li>{{'Establish a positive image for your brand' | translate}}</li>
                    <li>{{'Earn more visibility and keep existing customers while winning new ones' | translate}}</li>
                    <li>{{'Measure customer satisfaction using specific segmentation' | translate}}</li>
                    <li>{{'Unlock your employee evaluation score to view work quality at a glance' | translate}}</li>
                    <li>{{'Get your Net Promoter Score (NPS®) to predict growth and measure customer experiences' | translate}}</li>
                </ul>
            </div>
            <div class="right-side">
                <img src="assets/images/phone-1.png" alt="" class="phone">
                <img src="assets/images/facebook.svg" class="icon facebook" alt="">
                <img src="assets/images/website.svg" class="icon website" alt="">
                <img src="assets/images/tripadvisor.svg" class="icon trip" alt="">
                <img src="assets/images/instagram.svg" class="icon instagram" alt="">
            </div>
        </div>
        <div class="button-wrapper">
            <button [routerLink]="['/contact']">{{'GET MY CLICKREVIEW' | translate | uppercase}}</button>
        </div>
        <div class="image-back">
            <img src="assets/images/clients-back.png" alt="">
            <div class="text-back">
                <h3 [innerHTML]="'Did you know that, on average, ClickReview clients get a staggering 500% MORE reviews?' | translate"></h3>
                <p>{{'With CLICKREVIEW, you can start bringing in countless real, authentic reviews and foster trust and faith in your business – all in a matter of seconds!' | translate}}</p>
            </div>
        </div>
        <div class="columns-wrapper">
            <div class="column">
                <h4 [innerHTML]="'GROW YOUR SOCIAL FOLLOWING IN NO TIME' | translate"></h4>
                <p>{{'CLICKREVIEW makes it easier than ever to for your customers to leave you great reviews online, so you can build a bigger, more loyal audience and send your follower count soaring.' | translate}}</p>
            </div>
            <div class="column">
                <h4 [innerHTML]="'INSPIRE YOUR TEAM TO IMPROVE' | translate"></h4>
                <p>{{'Seeing as each CLICKREVIEW board carries its own unique serial number, you can effortlessly track the number of reviews you get from each one. What better way to get your staff inspired to boost their productivity and deliver better quality of service? Customer satisfaction will soar, all thanks to CLICKREVIEW and you!' | translate}}</p>
            </div>
            <div class="column">
                <h4 [innerHTML]="'REAL-TIME CUSTOMER FEEDBACK' | translate"></h4>
                <p>{{'Consumers always want to see that businesses have a constant influx of positive reviews, meaning that real-time feedback is the key to loyal business-customer relationships. By peeking into your customers’ minds, you’ll also be able to dramatically improve your business based on their feedback.' | translate}}</p>
            </div>
        </div>
    </div>
    <app-subscribe></app-subscribe>

    <!-- <div class="container">
        <div class="references-wrapper">
            <img src="assets/images/st.regis.svg" alt="">
            <img src="assets/images/hilton.svg" alt="">
            <img src="assets/images/hyat.svg" alt="">
            <img src="assets/images/marrlott.svg" alt="">
            <img src="assets/images/Four_Seasons_logo Copy 3.svg" alt="">
        </div>
    </div> -->
</div>