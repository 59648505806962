import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup
  formSuccess: boolean = false;
  constructor(private scroll: ScrollService, private mailService: MailService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
    this.contactForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(2)]),
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      message: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });

  }

  get f() {
    return this.contactForm.controls;
  }

  send() {
    let formData = 
    {
      "Name": this.f.name.value,
      "Email": this.f.email.value,
      "Message": this.f.message.value
    }

    if (this.contactForm.valid) {
      this.formSuccess = true;
      this.mailService.sendMail(formData).then((data)=>{

      })
    } else {
      console.log("error");
    }
  }

}
